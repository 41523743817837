<template>
  <div class="view-privacy">
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8">
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            tile
            dark
            flat>
            <v-toolbar-title>
              INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI<br>
              <span class="caption">ai sensi dell’art. 13 del Regolamento UE 679/2016</span>
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <p><strong>a) Identità e dati di contatto del Titolare del trattamento</strong><br>
            Titolare del trattamento è Keimfarben - Colori Minerali S.r.l. con sede legale in 39040 Naz Sciaves (BZ), Via Föche, 10. Sarà possibile contattare il Titolare del trattamento utilizzando i seguenti recapiti:<br>
            ▪ Tel: 0472 410158<br>
            ▪ Fax: 0472 412570<br>
            ▪ E-mail: <a href="mailto:info@keim.it">info@keim.it</a><br>
            ▪ E-mail certificata (PEC): <a href="mailto:keimfarben@pec.it">keimfarben@pec.it</a></p>

            <p><strong>b) Finalità e base giuridica del trattamento</strong><br>
            I Suoi dati verranno raccolti tramite la compilazione del form dedicato e trattati per la creazione di un account nell’applicazione KEIM Easy. Qualora il soggetto interessato sia già cliente di Keimfarben – Colori Minerali S.r.l., questo può accedere tramite autentificazione (e-mail e password). I Suoi dati saranno trattati per gestire le richieste di preventivo presenti nella sezione personale dell’applicazione. L’applicazione è disponibile sia nella versione web sia nella versione mobile e in entrambi i casi saranno trattati gli stessi dati comuni (nome, cognome, e-mail e numero di telefono).</p>

            <p><strong>c) Finalità di marketing</strong><br>
            I Suoi dati potranno essere utilizzati, previo Suo specifico e facoltativo consenso, anche ai fini di invio di materiale informativo e/o pubblicitario o per comunicazioni commerciali riguardanti nuovi prodotti o aggiornamenti e novità sui prodotti già esistenti. Il consenso al trattamento dei dati raccolti per le finalità promozionali è facoltativo e l’eventuale suo rifiuto a tale utilizzo non avrà conseguenze sull’esecuzione della prestazione.</p>

            <p><strong>d) Modalità di trattamento dei dati personali</strong><br>
            Sia la versione web che la versione mobile di KEIM Easy utilizzano Google Firebase, una piattaforma di sviluppo di Google che racchiude diversi servizi per lo sviluppo di applicazioni. Quali servizi di Google Firebase vengono utilizzati:<br>
            ⁃ Firebase Hosting e Cloud Functions, al fine di ospitare la versione web dell’applicazione e le funzioni eseguite in base alle azioni dell’utente (ad esempio esportazione in PDF dei preventivi), utilizzano e memorizzano gli indirizzi IP;<br>
            ⁃ Cloud Firestore, database che memorizza tutte le informazioni sui prodotti, preventivi e utenti;<br>
            ⁃ Firebase Authentication, servizio per l’autenticazione degli utenti che utilizza e memorizza indirizzi e-mail, password, numeri di telefono, user agents e indirizzi IP;<br>
            ⁃ Cloud Storage for Firebase, memorizza le immagini dei prodotti e i loghi delle aziende associate agli utenti che vengono utilizzati nei preventivi generati.<br>
            Per maggiori informazioni si rimanda all’indirizzo <a href="https://firebase.google.com/support/privacy?hl=en" target="_blank">https://firebase.google.com/support/privacy?hl=en</a>.</p>

            <p><strong>e) Soggetti a cui possono essere comunicati i dati</strong><br>
            I Suoi dati potranno essere comunicati a società che si occupano dello sviluppo e della manutenzione dell’App. I dati comunicati saranno unicamente quelli strettamente indispensabili all’esecuzione della prestazione richiesta e verranno preferite, ove possibile, modalità di trattamento in forma anonima. La comunicazione di tali dati è obbligatoria per poter scaricare l’applicazione e per poter creare i nuovi utenti. Un suo eventuale rifiuto alla comunicazione comporterà per la scrivente l’impossibilità di adempiere alla prestazione richiesta. I Suoi dati non saranno in alcun modo diffusi a terzi senza il Suo preventivo e specifico consenso.</p>

            <p><strong>f) Trasferimento dati ad un paese terzo o organizzazioni internazionali</strong><br>
            Ci impegniamo a fare in modo che il trattamento dei dati avvenga all’interno del territorio dell’Unione Europea. Quando ciò non è possibile per esigenze tecnico-organizzative, allora sosteniamo una verifica preliminare di sussistenza delle condizioni di legittimità prescritte dalla normativa. Copia dei Suoi dati è disponibile presso la sede del titolare, contattabile utilizzando i recapiti presenti nel punto a) della presente informativa.</p>

            <p><strong>g) Durata della conservazione dei dati personali</strong><br>
            I Suoi dati personali verranno conservati per il tempo strettamente necessario all’esecuzione del servizio prestato, nel rispetto delle altre normative civilistiche e fiscali che potrebbero determinare tempi di conservazione ulteriori.</p>

            <p><strong>h) Diritti dell’interessato</strong><br>
            In qualsiasi momento Lei ha diritto di richiedere al Titolare l’accesso ai Suoi dati, nonché la rettifica o la cancellazione degli stessi. Le verrà fornito riscontro entro 30 giorni in forma scritta (salvo Sua specifica richiesta di riscontro orale), anche con mezzi elettronici. Ha inoltre diritto a richiedere la limitazione del trattamento ovvero di opporsi allo stesso. Potrà infine richiedere la portabilità dei Suoi dati verso un altro titolare. In qualsiasi momento potrà inoltre revocare i consensi eventualmente prestati attraverso la presente informativa. Per revocare uno o più dei consensi prestati sarà sufficiente contattare uno dei recapiti indicati al punto a) della presente informativa.</p>

            <p><strong>i) Diritto di proporre reclamo all’Autorità di Controllo</strong><br>
            Laddove Lei ritenga che i Suoi dati siano stati trattati in modo illegittimo, ha il diritto di rivolgersi all’Autorità di Controllo per proporre reclamo.</p>

            <p><strong>j) Natura obbligatoria o facoltativa del conferimento dei Dati e conseguenze del mancato conferimento</strong><br>
            Il conferimento dei Suoi dati al Titolare è obbligatorio per i soli dati necessari per adempiere alle finalità indicate nel punto b) e il mancato conferimento all’utilizzo dei dati comporterà l’impossibilità di dare esecuzione al servizio.</p>

            <p><strong>k) Processi decisionali automatizzati</strong><br>
            Il Titolare non utilizza in alcun modo processi decisionali automatizzati che riguardano i Suoi dati personali.</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.view-privacy {
  padding-top: 2rem;
}

// Toglie il limite di altezza della toolbar e permette al testo di andare a capo
.v-card {
  header::v-deep {
    height: auto !important;

    .v-toolbar__content {
      height: auto !important;

      .v-toolbar__title {
        white-space: normal !important;
      }
    }
  }
}
</style>
